.nuclear-energy-act-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.nuclear-energy-act-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.nuclear-energy-act-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.nuclear-energy-act-logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.nuclear-energy-act-image {
  width: 50px;
  object-fit: cover;
}
.nuclear-energy-act-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.nuclear-energy-act-link {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.nuclear-energy-act-link01 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.nuclear-energy-act-link02 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.nuclear-energy-act-link03 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.nuclear-energy-act-cta-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.nuclear-energy-act-burger-menu {
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.nuclear-energy-act-icon {
  width: 36px;
  height: 36px;
}
.nuclear-energy-act-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.nuclear-energy-act-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.nuclear-energy-act-image1 {
  width: 50px;
  object-fit: cover;
}
.nuclear-energy-act-container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.nuclear-energy-act-icon02 {
  width: 24px;
  height: 24px;
}
.nuclear-energy-act-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.nuclear-energy-act-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.nuclear-energy-act-link04 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.nuclear-energy-act-link05 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.nuclear-energy-act-link06 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.nuclear-energy-act-link07 {
  text-decoration: none;
}
.nuclear-energy-act-cta-btn {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-100);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.nuclear-energy-act-bot {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.nuclear-energy-act-social-links-container {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.nuclear-energy-act-icon04 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.nuclear-energy-act-icon06 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.nuclear-energy-act-icon08 {
  width: 24px;
  height: 24px;
}
.nuclear-energy-act-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}
.nuclear-energy-act-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.nuclear-energy-act-text {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.nuclear-energy-act-cards-container {
  width: 874px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.nuclear-energy-act-feature-card {
  width: 405px;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.nuclear-energy-act-heading {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.nuclear-energy-act-text04 {
  color: var(--dl-color-gray-500);
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.nuclear-energy-act-feature-card1 {
  width: 396px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.nuclear-energy-act-heading1 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.nuclear-energy-act-text09 {
  color: var(--dl-color-gray-500);
  font-size: 18px;
  text-align: center;
}
.nuclear-energy-act-link08 {
  font-size: 20px;
  text-decoration: underline;
}
.nuclear-energy-act-text10 {
  font-size: 20px;
}
.nuclear-energy-act-text11 {
  font-size: 20px;
}
.nuclear-energy-act-text12 {
  font-size: 14px;
}
.nuclear-energy-act-features1 {
  width: 100%;
  height: 637px;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}
.nuclear-energy-act-heading-container1 {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.nuclear-energy-act-text14 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.nuclear-energy-act-link09 {
  font-size: 15px;
  text-decoration: underline;
}
.nuclear-energy-act-cards-container1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.nuclear-energy-act-feature-card2 {
  width: 30%;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.nuclear-energy-act-heading2 {
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 1.55;
  margin-bottom: var(--dl-space-space-tripleunit);
  text-transform: none;
  text-decoration: none;
}
.nuclear-energy-act-text18 {
  color: var(--dl-color-gray-500);
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.nuclear-energy-act-text19 {
  font-size: 15px;
}
.nuclear-energy-act-text20 {
  font-size: 25px;
}
.nuclear-energy-act-text21 {
  font-size: 15px;
}
.nuclear-energy-act-feature-card3 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.nuclear-energy-act-heading3 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.nuclear-energy-act-text24 {
  color: var(--dl-color-gray-500);
  font-size: 18px;
  text-align: center;
}
.nuclear-energy-act-text25 {
  font-size: 25px;
}
.nuclear-energy-act-feature-card4 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.nuclear-energy-act-heading4 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.nuclear-energy-act-text29 {
  color: var(--dl-color-gray-500);
  font-size: 20px;
  text-align: center;
}
.nuclear-energy-act-text30 {
  font-size: 25px;
}
.nuclear-energy-act-features2 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}
.nuclear-energy-act-cards-container2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.nuclear-energy-act-feature-card5 {
  width: 30%;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.nuclear-energy-act-heading5 {
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 1.55;
  margin-bottom: var(--dl-space-space-tripleunit);
  text-transform: none;
  text-decoration: none;
}
.nuclear-energy-act-text32 {
  color: var(--dl-color-gray-500);
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.nuclear-energy-act-text33 {
  font-size: 15px;
}
.nuclear-energy-act-text34 {
  font-size: 25px;
}
.nuclear-energy-act-text35 {
  font-size: 15px;
}
.nuclear-energy-act-feature-card6 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.nuclear-energy-act-heading6 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.nuclear-energy-act-text38 {
  color: var(--dl-color-gray-500);
  font-size: 18px;
  text-align: center;
}
.nuclear-energy-act-text39 {
  font-size: 25px;
}
.nuclear-energy-act-feature-card7 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.nuclear-energy-act-heading7 {
  text-align: center;
  margin-bottom: var(--dl-space-space-tripleunit);
}
.nuclear-energy-act-text43 {
  color: var(--dl-color-gray-500);
  font-size: 20px;
  text-align: center;
}
.nuclear-energy-act-text44 {
  font-size: 25px;
}
.nuclear-energy-act-contact {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.nuclear-energy-act-content-container {
  width: 50%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-self: stretch;
  align-items: flex-start;
  border-color: var(--dl-color-gray-700);
  border-width: 1px;
  flex-direction: column;
  justify-content: flex-start;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.nuclear-energy-act-text46 {
  margin-top: var(--dl-space-space-fiveunits);
  margin-bottom: var(--dl-space-space-fiveunits);
}
.nuclear-energy-act-locations-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.nuclear-energy-act-location-1 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.nuclear-energy-act-heading8 {
  font-size: 24px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.nuclear-energy-act-email {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.nuclear-energy-act-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.nuclear-energy-act-phone {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.nuclear-energy-act-icon12 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.nuclear-energy-act-link10 {
  display: contents;
}
.nuclear-energy-act-image2 {
  width: 306px;
  align-self: center;
  object-fit: cover;
  margin-right: 80px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .nuclear-energy-act-feature-card {
    align-self: center;
  }
  .nuclear-energy-act-feature-card2 {
    align-self: center;
  }
  .nuclear-energy-act-feature-card5 {
    align-self: center;
  }
  .nuclear-energy-act-locations-container {
    flex-wrap: wrap;
  }
  .nuclear-energy-act-location-1 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .nuclear-energy-act-image2 {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .nuclear-energy-act-links-container {
    display: none;
  }
  .nuclear-energy-act-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-radius4);
    background-color: var(--dl-color-gray-black);
  }
  .nuclear-energy-act-icon {
    fill: var(--dl-color-gray-white);
  }
  .nuclear-energy-act-heading-container {
    width: 100%;
  }
  .nuclear-energy-act-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .nuclear-energy-act-feature-card {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .nuclear-energy-act-feature-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .nuclear-energy-act-heading-container1 {
    width: 100%;
  }
  .nuclear-energy-act-cards-container1 {
    align-items: center;
    flex-direction: column;
  }
  .nuclear-energy-act-feature-card2 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .nuclear-energy-act-feature-card3 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .nuclear-energy-act-feature-card4 {
    width: 70%;
  }
  .nuclear-energy-act-cards-container2 {
    align-items: center;
    flex-direction: column;
  }
  .nuclear-energy-act-feature-card5 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .nuclear-energy-act-feature-card6 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .nuclear-energy-act-feature-card7 {
    width: 70%;
  }
  .nuclear-energy-act-contact {
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
  }
  .nuclear-energy-act-content-container {
    width: 100%;
  }
  .nuclear-energy-act-image2 {
    width: 227px;
    height: 227px;
    margin-right: 0px;
  }
}
@media(max-width: 479px) {
  .nuclear-energy-act-burger-menu {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .nuclear-energy-act-cta-btn {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .nuclear-energy-act-feature-card {
    width: 100%;
  }
  .nuclear-energy-act-feature-card1 {
    width: 100%;
  }
  .nuclear-energy-act-feature-card2 {
    width: 100%;
  }
  .nuclear-energy-act-feature-card3 {
    width: 100%;
  }
  .nuclear-energy-act-feature-card4 {
    width: 100%;
  }
  .nuclear-energy-act-feature-card5 {
    width: 100%;
  }
  .nuclear-energy-act-feature-card6 {
    width: 100%;
  }
  .nuclear-energy-act-feature-card7 {
    width: 100%;
  }
  .nuclear-energy-act-content-container {
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  .nuclear-energy-act-text46 {
    text-align: center;
  }
  .nuclear-energy-act-locations-container {
    margin-bottom: 0px;
    justify-content: center;
  }
  .nuclear-energy-act-location-1 {
    margin-right: 0px;
  }
  .nuclear-energy-act-image2 {
    width: 170px;
    height: 167px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
