.coal2nuclear-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.coal2nuclear-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.coal2nuclear-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.coal2nuclear-logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.coal2nuclear-image {
  width: 50px;
  object-fit: cover;
}
.coal2nuclear-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.coal2nuclear-link {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.coal2nuclear-link01 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.coal2nuclear-link02 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.coal2nuclear-link03 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.coal2nuclear-cta-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.coal2nuclear-burger-menu {
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.coal2nuclear-icon {
  width: 36px;
  height: 36px;
}
.coal2nuclear-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.coal2nuclear-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.coal2nuclear-image1 {
  width: 50px;
  object-fit: cover;
}
.coal2nuclear-container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.coal2nuclear-icon02 {
  width: 24px;
  height: 24px;
}
.coal2nuclear-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.coal2nuclear-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.coal2nuclear-link04 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.coal2nuclear-link05 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.coal2nuclear-link06 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.coal2nuclear-link07 {
  text-decoration: none;
}
.coal2nuclear-cta-btn {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-100);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.coal2nuclear-bot {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.coal2nuclear-social-links-container {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.coal2nuclear-icon04 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.coal2nuclear-icon06 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.coal2nuclear-icon08 {
  width: 24px;
  height: 24px;
}
.coal2nuclear-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}
.coal2nuclear-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.coal2nuclear-text {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.coal2nuclear-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.coal2nuclear-feature-card {
  width: 30%;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.coal2nuclear-image2 {
  width: 100px;
  object-fit: cover;
  margin-bottom: 20px;
}
.coal2nuclear-heading {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.coal2nuclear-text01 {
  color: var(--dl-color-gray-500);
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.coal2nuclear-feature-card1 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.coal2nuclear-image3 {
  width: 100px;
  object-fit: cover;
  margin-bottom: 20px;
}
.coal2nuclear-heading1 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.coal2nuclear-text09 {
  color: var(--dl-color-gray-500);
  font-size: 18px;
  text-align: center;
}
.coal2nuclear-text10 {
  font-size: 20px;
}
.coal2nuclear-text11 {
  font-size: 20px;
}
.coal2nuclear-text12 {
  font-size: 20px;
}
.coal2nuclear-text13 {
  font-size: 15px;
}
.coal2nuclear-text15 {
  font-size: 15px;
  text-decoration: underline;
}
.coal2nuclear-link09 {
  font-size: 15px;
  text-decoration: underline;
}
.coal2nuclear-feature-card2 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.coal2nuclear-image4 {
  width: 100px;
  object-fit: cover;
  margin-bottom: 20px;
}
.coal2nuclear-heading2 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.coal2nuclear-text19 {
  color: var(--dl-color-gray-500);
  font-size: 20px;
  text-align: center;
}
.coal2nuclear-text20 {
  color: var(--dl-color-gray-500);
  font-size: 20px;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.coal2nuclear-contact {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.coal2nuclear-content-container {
  width: 50%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-self: stretch;
  align-items: flex-start;
  border-color: var(--dl-color-gray-700);
  border-width: 1px;
  flex-direction: column;
  justify-content: flex-start;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.coal2nuclear-text24 {
  margin-top: var(--dl-space-space-fiveunits);
  margin-bottom: var(--dl-space-space-fiveunits);
}
.coal2nuclear-locations-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.coal2nuclear-location-1 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.coal2nuclear-heading3 {
  font-size: 24px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.coal2nuclear-email {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.coal2nuclear-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.coal2nuclear-phone {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.coal2nuclear-icon12 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.coal2nuclear-link10 {
  display: contents;
}
.coal2nuclear-image5 {
  width: 306px;
  align-self: center;
  object-fit: cover;
  margin-right: 80px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .coal2nuclear-feature-card {
    align-self: center;
  }
  .coal2nuclear-locations-container {
    flex-wrap: wrap;
  }
  .coal2nuclear-location-1 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .coal2nuclear-image5 {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .coal2nuclear-links-container {
    display: none;
  }
  .coal2nuclear-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-radius4);
    background-color: var(--dl-color-gray-black);
  }
  .coal2nuclear-icon {
    fill: var(--dl-color-gray-white);
  }
  .coal2nuclear-heading-container {
    width: 100%;
  }
  .coal2nuclear-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .coal2nuclear-feature-card {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .coal2nuclear-feature-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .coal2nuclear-feature-card2 {
    width: 70%;
  }
  .coal2nuclear-contact {
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
  }
  .coal2nuclear-content-container {
    width: 100%;
  }
  .coal2nuclear-image5 {
    width: 227px;
    height: 227px;
    margin-right: 0px;
  }
}
@media(max-width: 479px) {
  .coal2nuclear-burger-menu {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .coal2nuclear-cta-btn {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .coal2nuclear-feature-card {
    width: 100%;
  }
  .coal2nuclear-feature-card1 {
    width: 100%;
  }
  .coal2nuclear-feature-card2 {
    width: 100%;
  }
  .coal2nuclear-content-container {
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  .coal2nuclear-text24 {
    text-align: center;
  }
  .coal2nuclear-locations-container {
    margin-bottom: 0px;
    justify-content: center;
  }
  .coal2nuclear-location-1 {
    margin-right: 0px;
  }
  .coal2nuclear-image5 {
    width: 170px;
    height: 167px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
