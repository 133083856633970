.misconception-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.misconception-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.misconception-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.misconception-logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.misconception-image {
  width: 50px;
  object-fit: cover;
}
.misconception-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.misconception-link {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.misconception-link01 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.misconception-link02 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.misconception-link03 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.misconception-cta-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.misconception-burger-menu {
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.misconception-icon {
  width: 36px;
  height: 36px;
}
.misconception-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.misconception-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.misconception-image1 {
  width: 50px;
  object-fit: cover;
}
.misconception-container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.misconception-icon02 {
  width: 24px;
  height: 24px;
}
.misconception-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.misconception-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.misconception-link04 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.misconception-link05 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.misconception-link06 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.misconception-link07 {
  text-decoration: none;
}
.misconception-cta-btn {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-100);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.misconception-bot {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.misconception-social-links-container {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.misconception-icon04 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.misconception-icon06 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.misconception-icon08 {
  width: 24px;
  height: 24px;
}
.misconception-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}
.misconception-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.misconception-text {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.misconception-text01 {
  color: var(--dl-color-gray-black);
  font-size: 28px;
  box-shadow: rgb(212, 212, 212) 5px 5px 10px 0px;
  text-align: center;
  font-family: Open Sans;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.misconception-cards-container {
  width: 893px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.misconception-feature-card {
  width: 392px;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.misconception-image2 {
  width: 239px;
  height: 164px;
  object-fit: cover;
  margin-bottom: 20px;
}
.misconception-heading {
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  line-height: 1.55;
  margin-bottom: var(--dl-space-space-tripleunit);
  text-transform: none;
  text-decoration: none;
}
.misconception-text02 {
  color: var(--dl-color-gray-500);
  font-size: 20px;
  text-align: center;
}
.misconception-text03 {
  font-size: 20px;
}
.misconception-feature-card1 {
  width: 373px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.misconception-image3 {
  width: 357px;
  height: 195px;
  object-fit: cover;
  margin-bottom: 20px;
}
.misconception-heading1 {
  text-align: center;
  margin-bottom: var(--dl-space-space-tripleunit);
}
.misconception-text06 {
  color: var(--dl-color-gray-black);
  font-size: 24px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.misconception-text08 {
  color: var(--dl-color-gray-500);
  font-size: 20px;
  text-align: center;
}
.misconception-text11 {
  font-size: 15px;
}
.misconception-link08 {
  font-size: 15px;
  text-decoration: underline;
}
.misconception-contact {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.misconception-content-container {
  width: 50%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-self: stretch;
  align-items: flex-start;
  border-color: var(--dl-color-gray-700);
  border-width: 1px;
  flex-direction: column;
  justify-content: flex-start;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.misconception-text14 {
  margin-top: var(--dl-space-space-fiveunits);
  margin-bottom: var(--dl-space-space-fiveunits);
}
.misconception-locations-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.misconception-location-1 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.misconception-heading2 {
  font-size: 24px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.misconception-email {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.misconception-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.misconception-phone {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.misconception-icon12 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.misconception-link09 {
  display: contents;
}
.misconception-image4 {
  width: 306px;
  align-self: center;
  object-fit: cover;
  margin-right: 80px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .misconception-feature-card {
    align-self: center;
  }
  .misconception-locations-container {
    flex-wrap: wrap;
  }
  .misconception-location-1 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .misconception-image4 {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .misconception-links-container {
    display: none;
  }
  .misconception-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-radius4);
    background-color: var(--dl-color-gray-black);
  }
  .misconception-icon {
    fill: var(--dl-color-gray-white);
  }
  .misconception-heading-container {
    width: 100%;
  }
  .misconception-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .misconception-feature-card {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .misconception-feature-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .misconception-contact {
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
  }
  .misconception-content-container {
    width: 100%;
  }
  .misconception-image4 {
    width: 227px;
    height: 227px;
    margin-right: 0px;
  }
}
@media(max-width: 479px) {
  .misconception-burger-menu {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .misconception-cta-btn {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .misconception-feature-card {
    width: 100%;
  }
  .misconception-feature-card1 {
    width: 100%;
  }
  .misconception-content-container {
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  .misconception-text14 {
    text-align: center;
  }
  .misconception-locations-container {
    margin-bottom: 0px;
    justify-content: center;
  }
  .misconception-location-1 {
    margin-right: 0px;
  }
  .misconception-image4 {
    width: 170px;
    height: 167px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
