.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-image {
  width: 50px;
  object-fit: cover;
}
.home-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link01 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link02 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link03 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-cta-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-burger-menu {
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon {
  width: 36px;
  height: 36px;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-image1 {
  width: 50px;
  object-fit: cover;
}
.home-container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon02 {
  width: 24px;
  height: 24px;
}
.home-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.home-link04 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link05 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link06 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link07 {
  text-decoration: none;
}
.home-cta-btn {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-100);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-bot {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.home-social-links-container {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.home-icon04 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-icon06 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-icon08 {
  width: 24px;
  height: 24px;
}
.home-hero {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-hero-text-container {
  width: 35%;
  display: flex;
  align-items: flex-start;
  margin-left: 50px;
  flex-direction: column;
}
.home-heading {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text {
  color: var(--dl-color-gray-black);
  font-size: 25px;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-image2 {
  width: 50%;
  object-fit: contain;
}
.home-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.home-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}
.home-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text06 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text07 {
  color: var(--dl-color-gray-black);
  font-size: 28px;
  box-shadow: rgb(212, 212, 212) 5px 5px 10px 0px;
  text-align: center;
  font-family: Open Sans;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-cards-container {
  width: 893px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-feature-card {
  width: 392px;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-image3 {
  width: 128px;
  height: 138px;
  object-fit: cover;
  margin-bottom: 20px;
}
.home-heading1 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text08 {
  color: var(--dl-color-gray-500);
  font-size: 20px;
  text-align: center;
}
.home-text09 {
  font-size: 20px;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-text10 {
  font-size: 20px;
}
.home-text11 {
  font-size: 20px;
}
.home-text12 {
  font-size: 20px;
}
.home-feature-card1 {
  width: 373px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-image4 {
  width: 357px;
  height: 195px;
  object-fit: cover;
  margin-bottom: 20px;
}
.home-heading2 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text16 {
  color: var(--dl-color-gray-500);
  font-size: 20px;
  text-align: center;
}
.home-link08 {
  text-decoration: underline;
}
.home-services {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-service-card {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-card-content {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text19 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text20 {
  color: var(--dl-color-gray-black);
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-navlink {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
}
.home-service-card1 {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-card-content1 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text25 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text26 {
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-navlink1 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
}
.home-service-card2 {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-card-content2 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text32 {
  font-size: 35px;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text33 {
  color: var(--dl-color-gray-black);
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: Open Sans;
  font-weight: 700;
  line-height: 1.55;
  margin-bottom: var(--dl-space-space-fourunits);
  text-transform: none;
  text-decoration: none;
}
.home-navlink2 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
}
.home-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.home-contact {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-content-container {
  width: 50%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-self: stretch;
  align-items: flex-start;
  border-color: var(--dl-color-gray-700);
  border-width: 1px;
  flex-direction: column;
  justify-content: flex-start;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-text34 {
  margin-top: var(--dl-space-space-fiveunits);
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-locations-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.home-location-1 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-heading3 {
  font-size: 24px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-email {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-phone {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-icon12 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-link09 {
  display: contents;
}
.home-image5 {
  width: 306px;
  align-self: center;
  object-fit: cover;
  margin-right: 80px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .home-hero {
    flex-direction: column-reverse;
  }
  .home-hero-text-container {
    width: 80%;
  }
  .home-image2 {
    width: 80%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-feature-card {
    align-self: center;
  }
  .home-locations-container {
    flex-wrap: wrap;
  }
  .home-location-1 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-image5 {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .home-links-container {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-radius4);
    background-color: var(--dl-color-gray-black);
  }
  .home-icon {
    fill: var(--dl-color-gray-white);
  }
  .home-heading {
    text-align: center;
  }
  .home-text {
    text-align: center;
  }
  .home-text01 {
    text-align: center;
  }
  .home-heading-container {
    width: 100%;
  }
  .home-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .home-feature-card {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-feature-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-card-content {
    margin: 0px;
  }
  .home-card-content1 {
    margin: 0px;
  }
  .home-card-content2 {
    margin: 0px;
  }
  .home-contact {
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
  }
  .home-content-container {
    width: 100%;
  }
  .home-image5 {
    width: 227px;
    height: 227px;
    margin-right: 0px;
  }
}
@media(max-width: 479px) {
  .home-burger-menu {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-cta-btn {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-hero-text-container {
    width: 100%;
  }
  .home-image2 {
    width: 100%;
  }
  .home-feature-card {
    width: 100%;
  }
  .home-feature-card1 {
    width: 100%;
  }
  .home-service-card {
    width: 100%;
  }
  .home-service-card1 {
    width: 100%;
  }
  .home-service-card2 {
    width: 100%;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-content-container {
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-text34 {
    text-align: center;
  }
  .home-locations-container {
    margin-bottom: 0px;
    justify-content: center;
  }
  .home-location-1 {
    margin-right: 0px;
  }
  .home-image5 {
    width: 170px;
    height: 167px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
